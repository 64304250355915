import * as React from "react";
import { StringsProvider } from "@fitplan/context/lib-es/strings";
import { useStaticQuery, graphql } from "gatsby";
import deepmerge from "deepmerge";

import { Props } from "./common";

export const AuthFormStrings: React.FunctionComponent<Props> = ({
    children,
    data = {},
}) => {
    const query = useStaticQuery(graphql`
        query AuthFormStrings {
            signUpPage {
                facebookButton
                googleButton
                firstNamePlaceholder
                lastNamePlaceholder
                emailPlaceholder
                passwordPlaceholder
                repeatPasswordPlaceholder
                radioMaleText
                radioFemaleText
                radioOtherText
                spearatorText
                title
                footerText
                loginLinkText
                loginButton
                unknownError
                facebookError
                googleError
                signUpError
                emailCheckbox
                termsOfService
                privacyPolicy
                accountAgreement
            }
            loginPage {
                facebookButton
                googleButton
                emailPlaceholder
                passwordPlaceholder
                spearatorText
                title
                footerText
                signUpLinkText
                loginButton
                unknownError
                facebookError
                googleError
                loginError
                emailError
                passwordError
                forgotPasswordLinkText
            }
        }
    `);
    const strings = React.useMemo(() => deepmerge(query, data), [query, data]);
    return <StringsProvider data={strings}>{children}</StringsProvider>;
};
