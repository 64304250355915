export const gtagEvent = (eventName: string, data: { [key: string]: any }, isProduction?: boolean) => {
    if (typeof window === "undefined") {
        return;
    }
    if (isProduction && typeof (window as any).gtag === "function") {
        (window as any).gtag("event", eventName, {
            branch: (window as any).gtagBranch,
            ...data,
        });
    }
};

export const gtagPageView = (path: string, data: { [key: string]: any } = {}, isProduction?: boolean) => {
    gtagEvent("page_view", {
        page_path: path,
        ...data,
    }, isProduction);
};
