export * from "./gtag";
export * from "./tatari";

export const attemptTracking = (trackFn: () => void) => {
    const track = { fn: () => {}, counter: 10 };
    track.fn = () => {
        track.counter -= 1;
        try {
            trackFn();
        } catch (err) {
            if (track.counter > 0) {
                setTimeout(track.fn, 1000);
            }
        }
    };
    track.fn();
};